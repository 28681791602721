import * as React from 'react';
import { WorkLinks } from '../../components/home/WorkLinks';
import { Nav } from '../../components';
import GlobalLayout from '../../layouts/GlobalLayout';

const WorkPage = () => {
  return (
    <GlobalLayout>
      <Nav />
      <WorkLinks />
    </GlobalLayout>
  );
};

export default WorkPage;
